import React from "react";
import { useTranslation } from "../../context/TranslationContext";

const Container = ({ children }) => {
  const { productCategoriesTitle } = useTranslation();
  return (
    <div className="bg-brand-gray-7 py-8 hidden lg:block">
      <div className="container">
        <div className="flex items-center justify-center pb-4">
          <span className="font-bold text-xl lg:text-3xl text-[#333738] mb-4">
            {productCategoriesTitle}
          </span>
        </div>
        {children}
      </div>
    </div>
  );
};

export default Container;
