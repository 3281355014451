import Link from "next/link";
import React from "react";
import styles from "./InformationBanner.module.css";
import Image from "next/image";
import { useTranslation } from "../../context/TranslationContext";

const InformationBanner = ({ ssr }) => {
  const {
    informationBanner1Title,
    informationBanner2Title_balayi,
    informationBanner1SubTitle,
    informationBanner1ButtonText,
    documentNo,
    informationBanner2Title,
    informationBanner2ButtonText,
    informationBanner3Title,
    informationBanner3ButtonText,
  } = useTranslation();
  return (
    <div className={"container " + styles.InformationBanner}>
      <div className="md:mt-5 lg:mt-10 flex gap-x-2 overflow-x-scroll snap-x snap-mandatory pb-10 xl:overflow-x-visible">
        <div className="bg-product-type-color h-20 lg:h-28  rounded-lg overflow-hidden relative z-[1] lg:flex-[calc(50%-8px)] xl:flex-[calc(33%-8px)] flex-100 snap-start">
          <div className={`h-full ${styles.MaskContentImage}`}>
            <div className="flex items-center h-full pr-1 ml:pr-8">
              <div className="flex items-center pl-1 pr-1 ml:pl-8 ml:pr-8 h-full">
                <div className="relative w-16 h-full">
                  <Image
                    src="/icons/informationBanner1.svg"
                    layout={"fill"}
                    alt=""
                  />
                </div>
              </div>
              <div className="flex flex-col items-start">
                <span className="xl:text-[1.188rem] text-15 font-bold text-white">
                  {informationBanner1Title}
                </span>
                <span className="xl:text-[1.188rem] text-15 font-bold text-white pb-2">
                  {informationBanner1SubTitle}
                </span>
                <Link href={ssr.result.Banner1.url}>
                  <a
                    title={ssr.result.Banner1.title}
                    className="text-[0.875rem] font-semibold text-white opacity-70 border-b leading-[1.188rem]"
                  >
                    {informationBanner1ButtonText}
                  </a>
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className=" h-20 lg:h-28  rounded-lg overflow-hidden relative z-[1] lg:flex-[calc(50%-8px)] xl:flex-[calc(33%-8px)]  flex-100 snap-start">
          <div className="bg-information-color h-20 lg:h-28 lg:w-auto  overflow-hidden rounded-lg relative z-[1]">
            <div className={`h-full ${styles.MaskContentImage}`}>
              <div className="flex items-center h-full pr-1 ml:pr-8">
                <div className="flex flex-col items-center ml-2 ml:pl-11 pr-2 ml:pr-8">
                  <div className="relative w-20 h-6">
                    <Image
                      src="/icons/informationBanner2.svg"
                      layout="fill"
                      alt=""
                    />
                  </div>
                  <div className="flex flex-col mt-1 text-center">
                    <span className="text-[#6b6c6c] text-13">{documentNo}</span>
                    <span className="text-[#ff0000] text-13">14264</span>
                  </div>
                </div>
                <div className="flex flex-col items-start">
                  <span className="xl:text-[1.188rem] text-15 font-bold text-white pb-2">
                    {process.env.NEXT_PUBLIC_SITE == 3
                      ? informationBanner2Title_balayi
                      : informationBanner2Title}
                  </span>
                  <Link href={"https://www.tursab.org.tr/tr/ddsv"}>
                    <a target="_blank">
                      <span className="text-[0.875rem] font-semibold text-white opacity-70 border-b leading-[1.188rem]">
                        {informationBanner2ButtonText}
                      </span>
                    </a>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="bg-information-color2 h-20 lg:h-28  rounded-lg overflow-hidden relative z-[1] lg:flex-[calc(50%-8px)] xl:flex-[calc(33%-8px)]  flex-100 snap-start">
          <div className={`h-full ${styles.MaskContentImage}`}>
            <div className="flex items-center h-full pr-2 ml:pr-12">
              <div className="flex flex-col items-center pl-2 ml:pl-11 pr-2 ml:pr-8">
                <div className="relative w-16 h-16">
                  <Image
                    src="/icons/informationBanner3.svg"
                    layout="fill"
                    alt=""
                  />
                </div>
              </div>
              <div className="flex flex-col items-start">
                <span className="xl:text-[1.188rem] text-15 font-bold text-white pb-2">
                  {informationBanner3Title}
                </span>
                <Link href={ssr.result.Banner3.url}>
                  <a
                    title={ssr.result.Banner3.title}
                    className="text-[0.875rem] font-semibold text-white opacity-70 border-b leading-[1.188rem]"
                  >
                    {informationBanner3ButtonText}
                  </a>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default InformationBanner;
