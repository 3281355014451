import React from "react";
import { Icon } from "../../icons/icons";
import { useTranslation } from "../../context/TranslationContext";

const CallCenter = ({ phone }) => {
  const { callCenterName } = useTranslation();
  const last = phone.substr(phone.length - 13);
  const bold = phone.replace(last, "");
  return (
    <div className="hidden items-center mt-10 lg:flex order-last">
      <div className="text-call-color">
        <Icon name="CallCenter" />
      </div>
      <div className="flex flex-col ml-2">
        <span className="text-brand-black opacity-60 font-medium text-xs tracking-0">
          {callCenterName}
        </span>
        <span className="font-medium text-base text-brand-black tracking-11">
          <b>{bold}</b>
          {last}
        </span>
      </div>
    </div>
  );
};

export default CallCenter;
