import React from "react";
import styles from "./SearchBox.module.css";
import SearchboxWrapper from "./SearchboxWrapper";
// import dynamic from "next/dynamic";
// import LoaderInformationBanner from "../Loaders/LoaderInformationBanner";
import InformationBanner from "./InformationBanner";
// const InformationBanner = dynamic(() => import("./InformationBanner"), {
//   loading: () => <LoaderInformationBanner />,
// });
import { useTranslation } from "../../context/TranslationContext";
const SearchBox = ({ ssr }) => {
  const { searchBoxTitle } = useTranslation();
  return (
    <div
      className={
        "w-full bg-no-repeat lg:block lg:-mt-24 lg:pt-24 lg:h-auto pb-5  " +
        styles.BgSearchBoxPattern
      }
    >
      <SearchboxWrapper ssr={ssr} />
      <InformationBanner ssr={ssr} />
      <div className="container text-center">
        <span className="bg-brand-color-secondary inline-block rounded-md w-full  text-white py-2 px-4">
          {searchBoxTitle}
        </span>
      </div>
    </div>
  );
};

export default SearchBox;
