import React from "react";
import SliderCaption from "./SliderCaption";
import Social from "./Social";
import CallCenter from "./CallCenter";
import dynamic from "next/dynamic";
import isMobile from "is-mobile";
const SliderForm = dynamic(() => import("./SliderForm"), { ssr: false });
const SearchBoxRight = dynamic(() => import("./SearchBoxRight"), {
  ssr: false,
});
const SearchboxWrapper = ({ ssr }) => {
  const isPhone = isMobile();
  return (
    <div className="container flex flex-col-reverse xl:flex-row lg:bg-header-gra xl:bg-none overflow-hidden lg:overflow-visible">
      <div className="flex w-full xl:w-7/12 2xl:w-3/5 lg:-mt-24 lg:pt-24 flex-col ">
        <Social data={ssr.result.social} />
        <SliderCaption />
        {!isPhone ? <SliderForm className="hidden lg:flex" /> : null}
        <CallCenter phone={ssr.result.rez_tel} />
      </div>
      {!isPhone ? <SearchBoxRight /> : null}
    </div>
  );
};

export default SearchboxWrapper;
