import React from "react";
import { useTranslation } from "../../context/TranslationContext";

const ProductDestinationsContainer = ({ children }) => {
  const {
    productDestinationsContainerTitle,
    productDestinationsContainerCaption,
  } = useTranslation();
  return (
    <div className="bg-brand-gray-7 py-9 lg:hidden">
      <div className="container">
        <div className="flex items-center justify-center pb-2 flex-col">
          <span className="font-bold text-xl lg:text-3xl text-[#333738] mb-1">
            {productDestinationsContainerTitle}
          </span>
          <p>{productDestinationsContainerCaption}</p>
        </div>

        <div className="overflow-x-auto">
          <div className="flex">{children}</div>
        </div>
      </div>
    </div>
  );
};

export default ProductDestinationsContainer;
