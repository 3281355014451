import React, { useEffect } from "react";
import dynamic from "next/dynamic";
import LoaderProductCategories from "../Loaders/LoaderProductCategories";
import Container from "../ProductCategories/Container";
import ProductDestinationsContainer from "../ProductDestinations/ProductDestinationsContainer";
import Layout from "../Layout";
//const Structural = dynamic(() => import("../Structural"));
import LoaderStories from "../Loaders/LoaderStories";
const Stories = dynamic(() => import("../Stories"), {
  loading: () => <LoaderStories />,
});
import SearchBox from "../Index/SearchBox";
import Skeleton from "react-loading-skeleton";
import themes from "../../themes/themes";
const ProductDestinations = dynamic(
  () => import("../Index/ProductDestinations"),
  {
    loading: () => (
      <ProductDestinationsContainer>
        <Skeleton height={80} count={1} containerClassName={"flex-33 p-2"} />
        <Skeleton height={80} count={1} containerClassName={"flex-33 p-2"} />
        <Skeleton height={80} count={1} containerClassName={"flex-33 p-2"} />
      </ProductDestinationsContainer>
    ),
  }
);
const ProductCategories = dynamic(() => import("../Index/ProductCategories"), {
  loading: () => (
    <Container>
      <LoaderProductCategories />
    </Container>
  ),
});

const Products = dynamic(() => import("../Index/Products"));
const LastMinute = dynamic(() => import("../Index/LastMinute"));
const Review = dynamic(() => import("../Index/Review"));
const ShortTerms = dynamic(() => import("../Index/ShortTerms"));
const Faq = dynamic(() => import("../Index/Faq"));
const FooterBanner = dynamic(() => import("../FooterBanner"));
const LastMinuteModal = dynamic(() => import("../LastMinuteModal"));

const Home = ({ ssr }) => {
  return (
    <Layout ssr={ssr}>
      <Stories data={ssr.result.Story} />
      <SearchBox ssr={ssr} />
      <ProductDestinations data={ssr.result.ProductDestinations} />
      <ProductCategories />
      <Products HomeContent={ssr.result.HomeContent} all={ssr.result.All} />
      <LastMinute />
      <Review data={ssr.result.static.GetOffer} />
      <ShortTerms />
      <Faq data={ssr.result.faq} isHome="1" />
      {/* <HomeDescription ssr={ssr.result}></HomeDescription> */}
      {themes.FooterBanner && (
        <FooterBanner currentRoutingTypeId={ssr.RoutingTypeId} />
      )}
      {ssr.result.modal && <LastMinuteModal page={ssr.result.modal} />}
    </Layout>
  );
};

export default Home;
