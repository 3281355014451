import Head from "next/head";
import React from "react";
const fetcher = (url) => fetch(url).then((res) => res.json());
import Home from "../components/Pages/Home";

export async function getServerSideProps() {
  const repoInfo = await fetcher(
    process.env.NEXT_PUBLIC_API + "/Routing?url=/"
  );

  const headerContent = await fetcher(
    process.env.NEXT_PUBLIC_API + "/FullScreenMenu"
  );

  repoInfo.headerContent = headerContent;
  return {
    props: {
      data: repoInfo,
    },
  };
}
export default function Meta({ data }) {
  return <HomeComp fallback={data} />;
}

const HomeComp = ({ fallback }) => {
  const data = fallback;
  return (
    <>
      <Head>
        <title>{data.result.title}</title>
        <meta name="description" content={data.result.description}></meta>
        <meta name="keywords" content={data.result.keywords}></meta>
        <meta name="author" content={process.env.NEXT_PUBLIC_DOMAIN}></meta>
        <meta name="publisher" content={process.env.NEXT_PUBLIC_DOMAIN}></meta>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, maximum-scale=1"
        />
        <link rel="canonical" href={data.result.canonical} />
        {process.env.NEXT_PUBLIC_SITE == 3 ? (
          <link rel="icon" href="/favicon2.ico" />
        ) : (
          <link rel="icon" href="/favicon.ico" />
        )}
        <meta name="robots" content="index, follow" />
        {data?.result && (
          <>
            <meta property="og:url" content={data.result.canonical} />
            <meta property="og:type" content="website" />
            <meta property="og:title" content={data.result.title} />
            <meta property="og:description" content={data.result.description} />
            {data.result.resim && (
              <meta
                property="og:image"
                content={
                  process.env.NEXT_PUBLIC_CDN + "/uploads/" + data.result.resim
                }
              />
            )}
            <meta name="twitter:card" content="summary_large_image" />
            <meta
              property="twitter:domain"
              content={process.env.NEXT_PUBLIC_DOMAIN}
            />
            <meta property="twitter:url" content={data.result.canonical} />
            <meta name="twitter:title" content={data.result.title} />
            <meta
              name="twitter:description"
              content={data.result.description}
            />
            {data.result.resim && (
              <meta
                name="twitter:image"
                content={
                  process.env.NEXT_PUBLIC_CDN + "/uploads/" + data.result.resim
                }
              />
            )}
          </>
        )}
      </Head>
      <Home ssr={data} />
    </>
  );
};
