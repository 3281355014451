import React from "react";
import Skeleton from "react-loading-skeleton";
const LoaderProductCategories = () => {
  return (
    <>
      <div className="flex gap-y-[0.875rem] gap-x-5 mb-[0.875rem]">
        <div className="w-1/3">
          <Skeleton height={106} count={1} />
        </div>
        <div className="w-1/3">
          <Skeleton height={106} count={1} />
        </div>
        <div className="w-1/3">
          <Skeleton height={106} count={1} />
        </div>
      </div>
      <div className="flex gap-y-[0.875rem] gap-x-5">
        <div className="w-1/3">
          <Skeleton height={106} count={1} />
        </div>
        <div className="w-1/3">
          <Skeleton height={106} count={1} />
        </div>
        <div className="w-1/3">
          <Skeleton height={106} count={1} />
        </div>
      </div>
    </>
  );
};

export default LoaderProductCategories;
