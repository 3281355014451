import React from "react";
import Skeleton from "react-loading-skeleton";

const LoadingComp = () => {
  return (
    <>
      <div className="flex flex-col items-center mr-2">
        <div className="w-[4.25rem] h-[4.25rem] flex items-center justify-center rounded-full ">
          <Skeleton
            containerClassName="w-[4.25rem] h-[4.25rem] "
            className="h-full"
            circle={true}
          ></Skeleton>
        </div>
        <div className="flex flex-col text-center font-bold text-[0.688rem] mt-1 w-full">
          <Skeleton containerClassName="" count={2} height={8}></Skeleton>
        </div>
      </div>
    </>
  );
};
const LoaderStories = () => {
  return (
    <div className="block lg:hidden">
      <div className="container">
        <div className="overflow-x-auto flex flex-nowrap relative pb-4">
          <LoadingComp />
          <LoadingComp />
          <LoadingComp />
          <LoadingComp />
          <LoadingComp />
          <LoadingComp />
          <LoadingComp />
          <LoadingComp />
        </div>
      </div>
    </div>
  );
};

export default LoaderStories;
