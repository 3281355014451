import Link from "next/link";
import React from "react";
import { Icon } from "../../icons/icons";

const Social = ({ data }) => {
  return (
    <div className="hidden lg:flex xl:flex-col w-24 self-end items-center gap-y-1 gap-x-4 mt-2 xl:mt-7 min-h-[130px]">
      {data.instagram && (
        <Link href={data.instagram}>
          <a
            title={process.env.NEXT_PUBLIC_SITENAME + " Instagram"}
            target={"_blank"}
            rel="nofollow"
            className=" py-3"
          >
            <Icon name={"instagram"}></Icon>
          </a>
        </Link>
      )}
      {data.facebook && (
        <Link href={data.facebook}>
          <a
            title={process.env.NEXT_PUBLIC_SITENAME + " Facebook"}
            target={"_blank"}
            rel="nofollow"
            className=" py-3"
          >
            <Icon name={"facebook"}></Icon>
          </a>
        </Link>
      )}
      {data.youtube && (
        <Link href={data.youtube}>
          <a
            title={process.env.NEXT_PUBLIC_SITENAME + " Youtube"}
            target={"_blank"}
            rel="nofollow"
            className=" py-3"
          >
            <Icon name={"youtube"}></Icon>
          </a>
        </Link>
      )}
    </div>
  );
};

export default Social;
