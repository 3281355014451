import React, { useState, useEffect } from "react";
import Skeleton from "react-loading-skeleton";
import { GetSliderCaption } from "../api";
import { TypeAnimation } from "react-type-animation";
import { useTranslation } from "../../context/TranslationContext";
const SliderCaption = () => {
  const {
    forYou,
    mobileSliderCaption1,
    mobileSliderCaption1_balayi,
    mobileSliderCaption2,
    mobileSliderCaption3,
    mobileSliderCaption3_balayi,
  } = useTranslation();
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [items, setItems] = useState([]);
  useEffect(() => {
    GetSliderCaption().then((r) => {
      setItems(r.data);
      setIsLoaded(true);
    });
  }, []);

  return (
    <div className="flex flex-col lg:mt-10 2xl:mt-10 text-black lg:text-brand-black text-center xl:text-left xl:w-full order-2 lg:order-none">
      <div className="text-center mt-3 min-h-[82px] lg:hidden">
        <span className="w-full text-2xl font-bold">{forYou}</span>
        <TypeAnimation
          sequence={[
            process.env.NEXT_PUBLIC_SITE == 3
              ? mobileSliderCaption1_balayi
              : mobileSliderCaption1, // Types 'One'
            1500, // Waits 1s
            mobileSliderCaption2, // Deletes 'One' and types 'Two'
            1500, // Waits 2s
            process.env.NEXT_PUBLIC_SITE == 3
              ? mobileSliderCaption3_balayi
              : mobileSliderCaption3, // Types 'Three' without deleting 'Two'
            1500, // Waits 2s
          ]}
          wrapper="div"
          cursor={false}
          deletionSpeed={100}
          repeat={Infinity}
          className={"text-xl ml:text-2xl"}
        />
      </div>
      <div className="hidden lg:block">
        {error ? (
          <div>
            <Skeleton count={10} />
          </div>
        ) : !isLoaded ? (
          <>
            <div className="w-3/5 hidden lg:block">
              <Skeleton height={30} />
            </div>
            <div className="w-full lg:w-4/5 mt-2">
              <Skeleton height={110} className="mb-2" />
            </div>
            <div className="w-3/5 hidden lg:block">
              <Skeleton count={2} />
            </div>
          </>
        ) : (
          <>
            <span className="text-base font-bold leading-8 lg:leading-none lg:block lg:text-lg xl:text-25 lg:font-normal hidden">
              {items[0].aciklama2}
            </span>
            <span
              className="lg:block text-2xl lg:text-6xl xl:text-65 leading-none font-bold w-7/12 lg:w-full lg:pr-10 2xl:pr-44 mt-2 hidden"
              dangerouslySetInnerHTML={{
                __html: items[0].aciklama
                  .replace("{", "<span class='text-orange lg:text-brand-blue'>")
                  .replace("}", "</span>"),
              }}
            ></span>
            <small className="hidden lg:block text-tiny xl:text-15 font-normal text-brand-black xl:opacity-50 leading-5  xl:pr-44 mt-6">
              {items[0].aciklama3}
            </small>
            {/* {items &&
            items.map((item, key) => (
              <div
                className="w-full relative lg:hidden rounded-lg overflow-hidden"
                key={key}
              >
                <Image
                  src={item.filename + "?l=0"}
                  loader={Remote}
                  layout={"fill"}
                  objectFit={"cover"}
                  alt=""
                  priority
                  sizes="(max-width: 1024px) 70vw,(max-width: 425px) 60vw"
                ></Image>
                <div className="h-36 bg-black text-center px-2 bg-opacity-20 relative flex justify-center items-center  w-full ">
                  <div className="text-white text-lg font-bold ">
                    {item.aciklama ||
                      "Size özel havuzlu villada tatilini başlat"}
                  </div>
                </div>
              </div>
            ))} */}
          </>
        )}
      </div>
    </div>
  );
};

export default SliderCaption;
